import { Link, useParams } from '@remix-run/react';
import { Button } from './ui/button';
import { useState } from 'react';
import classNames from 'classnames';

export function Legal() {
  const { orgSlug, eventSlug, formOrder } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  // check with regex if formSlug is a number
  const formSlugIsNumber = formOrder && /^\d+$/.test(formOrder);

  // We don't need to render the legal link on the event page
  if (orgSlug && eventSlug && formSlugIsNumber) {
    return null;
  }

  return (
    <div className="flex items-end gap-4 p-1 text-xs text-muted-foreground">
      <Link to="/terms-of-service" target="_blank">
        Terms of Service
      </Link>
      <Link to="/privacy-policy" target="_blank">
        Privacy Policy
      </Link>
    </div>
  );
}
