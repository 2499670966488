import { ButtonLogout } from '../components/ButtonLogout';
import { Legal } from '../components/Legal';

export function Page({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <div className="pb-16 pt-14 md:pt-0">{children}</div>
      <div className="flex w-full justify-end pr-2">
        <Legal />
      </div>
      <div className="absolute right-4 top-4">
        <ButtonLogout />
      </div>
    </div>
  );
}
